const Routes = {
  "oferta-educativa": [
    { params:
      {
        "level": "bachillerato",
        "levelRoute": "oferta-educativa/bachillerato",
        "config": {
          title: "Bachillerato",
          promo: {
            urlImage: {
              mobile: "https://drive.google.com/uc?export=view&id=1wTgY7bg1cFqdEWKNLrsTO8GNR4u3T38_",
              desktop: "https://drive.google.com/uc?export=view&id=1wTgY7bg1cFqdEWKNLrsTO8GNR4u3T38_"
            },
            text: "",
            icon: "arrow_forward",
            color: "#FFD033",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Bachilleratos",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ] 
      }
    },
    { params:
      {
        "level": "licenciatura",
        "levelRoute": "oferta-educativa/licenciatura",
        "config": {
          title: "Licenciaturas",
          promo: {
            urlImage: {
              mobile: "https://drive.google.com/uc?export=view&id=19XGOqZziUfaXmB57vG1wxXcFSzWZ8ctR",
              desktop: "https://drive.google.com/uc?export=view&id=19XGOqZziUfaXmB57vG1wxXcFSzWZ8ctR"
            },
            text: "",
            icon: "arrow_forward",
            color: "#F6B5AF",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Licenciaturas",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
        ]
      }
    },
    { params:
      {
        "level": "maestria",
        "levelRoute": "oferta-educativa/maestria",
        "config": {
          title: "Maestrías",
          promo: {
            urlImage: {
              mobile: "https://drive.google.com/uc?export=view&id=11C7rUa3eAS6-tONNT666vk1jrayFBLHv",
              desktop: "https://drive.google.com/uc?export=view&id=11C7rUa3eAS6-tONNT666vk1jrayFBLHv"
            },
            text: "",
            icon: "arrow_forward",
            color: "#E84537",
            opacity: "multiply",
            height: "282px",
            enable: true,
            nobackground: false
          },
          banner: {
            image: {
              src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
              alt: "alumnos"
            },
            description: {
              title: "Maestrias",
              subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          },
          head: {
            title: "",
            description: "",
          },
        },
        "programs": [
         
        ] 
      }
    },
  ],
  "extension-universitaria": { 
    params: {
      "level": "extension-universitaria",
      "config": {
        title: "Extensión Universitaria",
        promo: {
          urlImage: {
            mobile: "https://drive.google.com/uc?export=view&id=1Ti7_CzkaVjV8jg5mxmaUzkF7rfYKKr34",
            desktop: "https://drive.google.com/uc?export=view&id=1Ti7_CzkaVjV8jg5mxmaUzkF7rfYKKr34"
          },
          text: "",
          icon: "arrow_forward",
          color: "#B9C3C8",
          opacity: "multiply",
          height: "282px",
          enable: true,
          nobackground: false
        },
        banner: {
          image: {
            src: "https://www.uane.edu.mx/multi/images/programas/becas.jpg",
            alt: "alumnos"
          },
          description: {
            title: "Extensión Universitaria",
            subtitle: "Elige una lorem ipsum sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          }
        },
        head: {
          title: "",
          description: "",
        },
      },
      "programs": [
      ] 
    }
  },
  "faq": [
    { params: { "section": "general" } },
    { params: { "section": "becas" } },
    { params: { "section": "finanzas" } },
  ],
  "blog": [
    { params:
      {
        "level": "entrada",
        "levelRoute": "extension-universitaria/",
        "config": {
          title: "entradas de blog",
          head: {
            title: "",
            description: "",
          },
        },
        "entries": [
        ] 
      }
    },
  ]
}

export default Routes